import React from 'react';
import Login from './components/register/register';


class App extends React.Component {


  render() {
    return (
      <>
        <Login></Login>
      </>
    );
  }
}

export default App;