import React from 'react';
import {Button} from 'antd'
import styles from './Logintype.module.css'

export default class LoginType extends React.PureComponent {
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {}),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const value = props.value || '';
    this.state = {
      checkType: value,
    };
  }

  onChangeType = (checkType) => () => {
    if (!('value' in this.props)) {
      this.setState({checkType});
    }
    this.triggerChange({checkType});
  }
  triggerChange = changedValue => {
    const {onChange} = this.props;
    if (onChange) {
      onChange({
        ...changedValue,
      });
    }
  };

  render() {
    const {
      config: {
        title,
        east,
        west,
        shanghai
      },
    } = this.props;
    const {
      checkType
    }=this.state;
    return (
      <div className={styles['container']}>
        <span>{title}</span>
        <div className={styles['buttons']}>
          <Button type={checkType === 'west' ? 'primary' : 'default'} onClick={this.onChangeType('west')}>{west.title}</Button>
          <Button type={checkType === 'east' ? 'primary' : 'default'} onClick={this.onChangeType('east')}>{east.title}</Button>
          {/* <Button type={checkType === 'shanghai' ? 'primary' : 'default'} onClick={this.onChangeType('shanghai')}>{shanghai.title}</Button> */}

        </div>
      </div>
    )
  }
}
