export class DataServer {
  getToken(type) {
    let token = '';
    let key = token;
    if (type == 'url') {
      var href = window.location.href;
      token = getTokenFromUrl(href, '?' + key + '=');
      if (!checkToken(token)) {
        token = getTokenFromUrl(href, '&' + key + '=');
      }
    }
    return token;
  }

  getStorage(key, type) {
    let value;
    if (type == 'local') {
      value = window.localStorage.getItem(key);
    } else {
      value = window.sessionStorage.getItem(key);
    }
    if (value) {
      value = JSON.parse(value);
    }
    return value;
  }

  setStorage(key, value, type) {
    if (value) {
      const jsonVal = JSON.stringify(value);
      if (type == 'local') {
        window.localStorage.setItem(key, jsonVal);
      } else {
        window.sessionStorage.setItem(key, jsonVal);
      }
    }
  }

  clearStorage(key, type) {
    if (type == 'local') {
      if (key == null || key == '') {
        window.localStorage.clear();
      } else {
        window.localStorage.removeItem(key);
      }
    } else {
      if (key == null || key == '') {
        window.sessionStorage.clear();
      } else {
        window.sessionStorage.removeItem(key);
      }
    }
  }
}

function checkToken(token) {
  return token != null && token != '';
}

function getTokenFromUrl(url, tag) {
  if (url.indexOf(tag) > 0) {
    let token = url.substring(url.indexOf(tag) + tag.length);
    if (token.indexOf('&') >= 0) {
      token = token.substring(0, token.indexOf('&'));
    }
    return token;
  }
  return null;
}