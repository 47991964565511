import React from 'react';

import {
  Form, Icon, Input, Button, Checkbox,
  message
} from 'antd';
import LoginType from './logintype';
import styles from './Register.module.css';
import logo from './unitUserIcon.gif';
import {DataServer} from "../../utils/utils";


class LoginForm extends React.Component {
  API_USER_LOGIN = '/sys/pcLogin';
  API_USER_JILIN = '/sys/pcLogin'
  API_BIT_LOGIN = '/sys/bitLogin'

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      isLoading: false,
    }
    message.config({
      top: 300,
      duration: 2,
      maxCount: 3,
    });
    this.dataService = new DataServer();
  }

  changeButtonLoadingState() {
    this.setState({
      isLoading: !this.state.isLoading,
    })
  }

  /*
  *
  * 表单提交
  * */
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: {
        resetFields,
        validateFields
      }
    } = this.props;
    validateFields((err, values) => {
      if (!err) {
        let {
          loginType: {
            checkType: project
          }
        } = values;
        const {
          username: login,
          password,
          remember
        } = values;
        const filter = {
          login,
          password,
          project,
        }
        this.changeButtonLoadingState();
        // this.postData(filter); // 原来直接登录
        this.getBit(filter);
      }
    });
  };

  async getBit (filter = {}) {
    // const { project } = filter;
    // let domain = ''
    // if (project === 'west') {
    //   // eslint-disable-next-line no-undef
    //   domain = serverConfig.apiUrl_WEST;
    // } else if (project === 'east') {
    //   // eslint-disable-next-line no-undef
    //   domain = serverConfig.apiUrl_EAST;
    // } else {
    //   // eslint-disable-next-line no-undef
    //   domain = serverConfig.apiUrl_JILIN;
    // }
    // let api = this.API_BIT_LOGIN
    const BitLogin = 'https://pm.tcm.ccgme-cmda.cn/userInfomation/sys/bitLogin'
    const promise = await fetch(BitLogin, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    });
    const response = await promise.json();
    if(response.code === 0) {
      this.postData(filter)
    }else{
      window.location.href = 'https://mvw-public.oss-cn-beijing.aliyuncs.com/support/system-upgrade.html'
    }
  }

  async postData(filter = {}) {
    const {
      form: {
        resetFields,
      }
    } = this.props;
    const {
      project
    } = filter;
    // eslint-disable-next-line no-undef
    let domain = ''
    if (project === 'west') {
      // eslint-disable-next-line no-undef
      domain = serverConfig.apiUrl_WEST;
    } else if (project === 'east') {
      // eslint-disable-next-line no-undef
      domain = serverConfig.apiUrl_EAST;
    } else {
      // eslint-disable-next-line no-undef
      domain = serverConfig.apiUrl_JILIN;
    }
    try {
      let api = '';
      if (project == 'shanghai') {
        api = this.API_USER_JILIN;
      } else {
        api = this.API_USER_LOGIN;
      }
      const promise = await fetch(`${domain}${api}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(filter)
      });
      const response = await promise.json();
      const {
        code,
        msg,
        result
      } = response;
      if (code === 0) {
        message.success('登录成功');
        const {
          token,
          userType
        } = result;
        this.saveUserInfo();

        resetFields();

        setTimeout(() => {
          this.handleLoginResult(project, {
            token,
            userType
          })
        }, 0)
      } else {
        message.error(msg)
      }
    } catch (err) {
      console.log(err)
    } finally {
      this.changeButtonLoadingState();
    }
  }

  /*
  *
  *处理用户登录结果
  * */
  handleLoginResult(project, userInfo) {
    const {
      userType,
      token
    } = userInfo;
    //const source = project === 'west' ? 'process-west' : 'process-east';

    //const query = encodeURIComponent(`token=${token}&source=${source}`);
    if (project === 'west') {
      const query = encodeURIComponent(`token=${token}&source=process-west`);
      // eslint-disable-next-line no-undef
      const href = `${serverConfig.westAddress}?${query}`
      window.location.href = href
    } else if (project === 'east') {
      const query = encodeURIComponent(`token=${token}&source=process-east`);
      // eslint-disable-next-line no-undef
      window.location.href = `${serverConfig.eastAddress}?${query}`;
    } else {
      const query = encodeURIComponent(`token=${token}&source=process-jilin`);
      // eslint-disable-next-line no-undef
      window.location.href = `${serverConfig.jilinAddress}?${query}`;
    }
  }

  /*
  * 检测是否选择了培训类别
  * */
  checkTrainType = (rule, value, callback) => {
    if (value.checkType) {
      callback();
      return;
    }
    callback('请选择培训类别!');
  };

  /*
  * 检测是否操作了所有表单项
  * */
  isTouchedAll() {
    const {isFieldTouched} = this.props.form;
    return isFieldTouched('username') && isFieldTouched('password') && isFieldTouched('loginType')
  }

  /*
  * 检测表单是否有错误
  * */
  hasError() {
    const {getFieldsError} = this.props.form;

    // for (let value of Object.values(getFieldsError())) {
    //   if (value) {
    //     return true;
    //   }
    // }
    const errObj = getFieldsError()
    for (const key in errObj) {
      if (errObj[key]) {
        return true;
      }
    }
    return false;
  }

  /*
  *判断提交按钮是否是disabled 状态
  * */
  isButtonDisabled() {
    let isDisabled = false;
    if (this.isNeedCheck()) {
      const hasError = this.hasError();
      const isTouched = this.isTouchedAll();
      isDisabled = !isTouched;
      if (isTouched) {
        isDisabled = hasError
      }
    }
    return isDisabled;
  }

  /*
 * 保存用户名和密码
 * */
  saveUserInfo() {
    const {
      form: {
        getFieldsValue
      }
    } = this.props;
    const val = getFieldsValue();

    const {
      username: login,
      password,
      remember,
      loginType: {
        checkType: project
      }
    } = val;
    remember
      ? this.dataService.setStorage('NH3fwrFW3MWL0dwC', {
        login,
        password
      }, 'local')
      : this.dataService.clearStorage('NH3fwrFW3MWL0dwC', 'local');
    remember ? this.dataService.setStorage('remember', true, 'local')
      : this.dataService.clearStorage('remember', 'local');
    remember ? this.dataService.setStorage('medicineType', project, 'local')
      : this.dataService.clearStorage('medicineType', 'local');

  }

  getUserInfo() {
    return this.dataService.getStorage('NH3fwrFW3MWL0dwC', 'local') || {
      login: '',
      password: ''
    };
  }

  /*
  * 检测登录按钮是否需要跳过disabed 状态
  * */
  isNeedCheck() {
    const {
      form: {
        getFieldsValue
      }
    } = this.props;
    const val = getFieldsValue();
    const {
      username,
      password,
      loginType: {
        checkType
      } = {}
    } = val;
    return !(username && password && checkType);
  }

  render() {
    const {
      form: {getFieldDecorator},
      config: {
        title,
        categoryConfig
      },
    } = this.props;
    let isDisabled = this.isButtonDisabled();
    return (
      <Form onSubmit={this.handleSubmit} className={styles["login-form"]}>
        <div className={styles['logo']}><img src={logo} alt="logo"/></div>
        <h3>{title}</h3>
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{
              required: true,
              message: '请输入用户名!'
            }],
          })(
            <Input
              prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
              placeholder="用户名"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{
              required: true,
              message: '请输入密码!'
            }],
          })(
            <Input
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
              type="password"
              placeholder="密码"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('loginType', {
            initialValue: '',
            rules: [{validator: this.checkTrainType}],
          })(
            <LoginType
              config={categoryConfig}
              // onChange={this.onChangeType}
            ></LoginType>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: false,
          })(<Checkbox>记住我</Checkbox>)}
          {/*<a className={styles["login-form-forgot"]} href="">*/}
          {/*忘记密码*/}
          {/*</a>*/}
          <Button loading={this.state.isLoading} disabled={isDisabled} type="primary" htmlType="submit" className={styles["login-form-button"]}>
            登录
          </Button>
        </Form.Item>
      </Form>
    );
  }

  addClick() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        this.handleSubmit(e)
      }
    })
  }

  componentDidMount() {
    this.addClick();
    const {
      login = '',
      password = ''
    } = this.getUserInfo();

    const remember = this.dataService.getStorage('remember', 'local');
    const project = this.dataService.getStorage('medicineType', 'local')
    const {
      form: {
        setFieldsValue
      }
    } = this.props;
    setFieldsValue({
      username: login,
      password: password,
      remember,
      loginType: {
        checkType: project
      },
    })
  }
}

LoginForm.defaultProps = {
  config: {
    title: '住培过程管理系统',
    categoryConfig: {
      title: '请选择培训类别',
      west: {
        title: '西医',
      },
      east: {
        title: '中医'
      },
      shanghai: {
        title: '上海'
      }
    }
  }
}


export default Form.create({
  name: 'normal_login',
})(LoginForm)
